@import "./../../../../../src/assets/scss/variable.scss";

  .graph {
     &__title {
      color: $neutral-65;
      text-transform: uppercase;
    }

    &__count {
      font-size: $font-size-xl;
      line-height: 1;
      color: $neutral;
    }

    &__label{
      font-size: $font-size-small;
      color: $neutral-75;
    }
    &__percent{
      font-size: $font-size-xs;
      line-height: 17px;
    }

    &__percent svg {
      stroke: $success;
    }

    &__percent.negative svg{
      transform: rotate(180deg);
      stroke: $red;
    }
  }
