@charset "UTF-8";

.react-datepicker__input-container input {
  min-width: 158px;
  max-width: 850px;
  min-height: 32px;
  outline: none;
  box-shadow: none;
  border-radius: 34px;
  border: 1px solid #4E938E;
  padding-left: 34px !important;
  cursor: pointer;
  background: url("data:image/svg+xml,%3Csvg height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z' fill='%23929597'/%3E%3C/svg%3E") no-repeat right 10px center #f1f6f6;
  background-size: 20px;
  font-size: 14px;
}

.react-datepicker__input-container input:hover {
  border-color: #1B7899;
}

.react-datepicker__input-container input::placeholder {
  opacity: 1;
  color: #262b2f;
}

.react-datepicker__input-container input:placeholder-shown {
  background-color: #ffffff;
  border: 1px solid #BEBFC1;
}

.react-datepicker__input-container svg {
  width: 16px;
  height: 16px;
  margin-left: 2px;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker {
  border-color: #4E938E !important;
}

input.react-datepicker-ignore-onclickoutside {
  border-color: #1B7899 !important;
  background-color: #f1f6f6 !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: #f1f6f6 !important;
}

.react-datepicker__header {
  background-color: #f1f6f6 !important;
  border-bottom-color: #4E938E !important;;
  line-height: 22px !important;;
}
