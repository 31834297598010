@import "./../../../../src/assets/scss/variable.scss";

.bulkMenu {
  &__container {
    left: 0;
    right: 0;
    bottom: 12px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 1;
  }

  &__menu {
    color: white;
    background: $neutral;
    gap: $gap;
    height: 44px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 12px 0 16px;
    margin: 0 32px;
    overflow: hidden;
    pointer-events: auto;
  }

  &__line {
    display: flex;
    gap: $gap;
    flex-shrink: 1;
    flex-grow: 1;
    overflow: hidden;
  }

  &__info {
    color: white;
    display: flex;
    gap: $gap;
    flex-shrink: 0;
    align-items: center;
    font-size: $font-size-medium;
  }

  &__close {
    opacity: 1;
    text-shadow: none;

    span {
      line-height: 1;
      font-size: 28px; // TODO: make var
      color: white;
      font-weight: 300;
    }

    &:hover {
      opacity: 1;

      span {
        color: white;
      }
    }
  }
}