@import "./../../../../../src/assets/scss/variable.scss";
.tooltip {
    &__container {
      background: #ffffff;
      box-shadow: 1px 4px 8px  #{$neutral}1F;
      border-radius: 4px;
      border: 1px $neutral-12 solid;
      color: $neutral;
      font-size: $font-size-base;
    }

  }
