@import "./../../../../../src/assets/scss/variable.scss";

.button {
  border-radius: 8px;
  border: 1px solid $neutral-12;
  background-color: #ffffff;
  box-shadow: 2px 6px 12px 0  #{$neutral}1F;
  text-align: left;
  width: 100%;
  max-width: 300px;

  &:hover,
  &_selected {
    border: 1px solid $primary-color;
    background-color: $primary-color-16;
  }

  & div {
    color: $neutral-75;
    white-space: nowrap;
  }

  &__title {
    color: $neutral-75;
    font-size: $font-size-small;
    text-transform: uppercase;
  }

  &__count {
    font-size: $font-size-xl;
    color: $neutral;
  }
}