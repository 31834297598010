.sidebar,
body > .navbar-collapse{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 260px;
    display: block;
    z-index: 1;
    color: #fff;
    font-weight: 200;
    background-size: cover;
    background-position: center center;

    .sidebar-wrapper{
        position: relative;
        max-height: calc(100vh - 75px);
        min-height: 100%;
        overflow: auto;
        width: 260px;
        z-index: 4;
        padding-bottom: 100px;
    }

    .sidebar-background{
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;
    }

    .logo{
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        position: relative;
        z-index: 4;

        p{
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $white-color;
            line-height: 20px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }

        .simple-text{
            text-transform: uppercase;
            font-size: $font-size-large;
            color: $white-color;
            text-align: center;
            font-weight: $font-weight-normal;
        }
    }

    .logo-tim{
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img{
            width: 60px;
            height: 60px;
        }
    }

    .nav{
        margin-top: 10px;
        float: none;
        display: block;

        li{
            .nav-link{
                color: $white-color;
                margin: 5px 15px;
                opacity: .86;
                border-radius: 4px;
                display: block;
                padding: 10px 15px;

                &:hover{
                    background: rgba(255, 255, 255, 0.13);
                    opacity: 1;
                }

                p{
                    margin: 0;
                    line-height: 31px;
                    font-size: 12px;
                    font-weight: 600;
                    text-transform: uppercase;
                    display: inline-flex;
                }

                i{
                    font-size: 28px;
                    margin-right: 15px;
                    width: 30px;
                    text-align: center;
                    vertical-align: middle;
                    float: left;
                }
            }

            &:hover .nav-link{
                background: rgba(255,255,255,0.13);
                opacity: 1;
            }

            &.active .nav-link{
                color: $white-color;
                opacity: 1;
                background: rgba(255,255,255,0.23);

            }

            &.separator{
                margin: 15px 0;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);

                & + .nav-item {
                    margin-top: 31px;
                }
            }

        }



        .caret{
            margin-top: 13px;
            position: absolute;
            right: 30px;
        }

        .active-pro{
            position: absolute;
            width: 100%;
            bottom: 10px;

            a{
                color: $white-color !important;

            }
        }

        .nav-link{
            color: $white-color;
            margin: 5px 15px;
            opacity: .86;
            border-radius: 4px;
            text-transform: uppercase;
            line-height: 30px;
            font-size: 12px;
            font-weight: 600;
        }
    }

    .logo{
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        svg {
            width: 117px; // from form.com logo
        }
        p{
            font-size: 20px;
            margin: 8px 5px 0;
            color: $white-color;
            line-height: 20px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }

        .simple-text{
            text-transform: uppercase;
            padding: $padding-zero;
            font-size: $font-size-large;
            color: $white-color;
            text-align: center;
            font-weight: $font-weight-normal;
            line-height: 1;
        }
    }

    .logo-tim{
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img{
            width: 60px;
            height: 60px;
        }
    }

    &:after,
    &:before{
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
    }

    &:before{
        opacity: .33;
        background: #000000;
    }

    &:after{
       @include icon-gradient($new-purple, $purple-color-bottom);
       z-index: 3;
       opacity: 1;
    }

    &[data-image]:after,
    &.has-image:after{
        opacity: .77;
    }

    &[data-color="black"]:after{
        @include icon-gradient($default-states-color, $default-states-color);
    }
    &[data-color="shelfwise"]:after{
        @include icon-gradient($shelfwise-states-color, $shelfwise-states-color);
    }
    &[data-color="blue"]:after{
        @include icon-gradient($new-dark-blue, $blue-color-bottom);
    }
    &[data-color="azure"]:after{
        @include icon-gradient($new-blue, $azure-color-bottom);
    }
    &[data-color="green"]:after{
        @include icon-gradient($new-green, $green-color-bottom);
    }
    &[data-color="orange"]:after{
        @include icon-gradient($new-orange, $orange-color-bottom);
    }
    &[data-color="red"]:after{
        @include icon-gradient($new-red, $red-color-bottom);
    }
    &[data-color="purple"]:after{
        @include icon-gradient($new-purple, $purple-color-bottom);
    }
}

.content {
    padding-top: 30px;
}

.main-panel{
    background: rgba(203,203,210,.15);
    position: relative;
    float: right;
    width: $sidebar-width;
    min-height: 100%;

    > .content{
        padding: 30px 15px;
        min-height: calc(100% - 123px);
    }

    > .footer{
        border-top: 1px solid #e7e7e7;
    }

    .navbar{
        margin-bottom: 0;
    }
}

.sidebar,
.main-panel{
    overflow: auto;
    max-height: 100%;
    height: 100%;
    -webkit-transition-property: top,bottom;
    transition-property: top,bottom;
    -webkit-transition-duration: .2s,.2s;
    transition-duration: .2s,.2s;
    -webkit-transition-timing-function: linear,linear;
    transition-timing-function: linear,linear;
    -webkit-overflow-scrolling: touch;
}


.fixed-plugin{

    .dropdown{
        .dropdown-menu{
            @include transform-translate-3d-0(-5%);
            border-radius: 10px;
            li.adjustments-line{
                border-bottom: 1px solid #ddd;
            }
            li{
                padding: 5px 2px !important;
            }
            .button-container{
                a{
                    font-size: 14px;
                }
            &.show{
                @include transform-translate-3d-0(0%);
                transform-origin: 0 0;
                left: -303px !important;
            }
            }

        }
    }

}

.fixed-plugin .dropdown .dropdown-menu{
    @include transform-translate-y-dropdown(-5%);
    top: -40px !important;
    opacity: 0;
    left: -303px !important;
    transform-origin: 0 0;
}
.fixed-plugin .dropdown.show .dropdown-menu{
    opacity: 1;
    @include transform-translate-y-dropdown(0%);
    transform-origin: 0 0;
    left: -303px !important;
}

.fixed-plugin .dropdown-menu:before,
.fixed-plugin .dropdown-menu:after{
    content: "";
    display: inline-block;
    position: absolute;
    top: 65px;
    width: 16px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);

}
.fixed-plugin .dropdown-menu:before{
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid rgba(0,0,0,0.2);
    border-top: 16px solid rgba(0,0,0,0);
    right: -16px;
}

.fixed-plugin .dropdown-menu:after{
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid #fff;
    border-top: 16px solid rgba(0,0,0,0);
    right: -15px;
}

.modal{
    &.show{
        .modal-dialog{
            -webkit-transform: translate(0, 30%);
            -o-transform: translate(0, 30%);
            transform: translate(0, 30%);
        }
    }
    &.modal-mini{
        .modal-dialog{
            max-width: 255px;
            margin: 0 auto;
        }
    }
    .modal-content{
        .modal-header{
            border-bottom: none;
            padding-top: 24px;
            padding-right: 24px;
            padding-bottom: 0;
            padding-left: 24px;

            .modal-profile{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                text-align: center;
                line-height: 5.7;
                box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.3);

                i{
                    font-size: 32px;
                    padding-top: 24px;
                }
            }
        }

        .modal-body{
            padding-top: 24px;
            padding-right: 24px;
            padding-bottom: 16px;
            padding-left: 24px;
            line-height: 1.9;
        }

        .modal-body + .modal-footer{
            padding-top: 0;
        }

        .modal-footer{
            border-top: none;
            padding-right: 24px;
            padding-bottom: 16px;
            padding-left: 24px;
            -webkit-justify-content: space-between;
            justify-content: space-between;

            .btn{
                margin: 0;
                padding-left: 16px;
                padding-right: 16px;
                width: auto;

                &:hover,
                &focus{
                    text-decoration: none;
                }
            }
        }
    }
}

.nav-tabs {
    position: relative;
    min-height: 52px;
    flex-wrap: nowrap;

    .btn {
        align-self: center;
    }

    .nav-tabs_btn {
        position: absolute;
        left: 70px;
        top: 50%;
        margin-top: -12px;
        width: 24px;
        height:24px;
        cursor: pointer;
        display: none;

        span {
            display: block;
            background: $default-color;
            position: absolute;
            width: 100%;
            height: 2px;
            transition: all 0.5s;
            top: 2px;

            &:nth-child(2),
            &:nth-child(3) {
                top: 10px;
            }

            &:nth-child(4) {
                top: 18px;
            }
        }
    }
    &-open {
        .nav-tabs_btn span{
            &:nth-child(1),
            &:nth-child(4) {
                opacity: 0;
            }
            &:nth-child(2) {
                transform: rotate(-45deg);
            }
            &:nth-child(3) {
                transform: rotate(45deg);
            }
        }
    }

    .nav-tabs-container {
        align-items: center;
        flex-wrap: wrap;
    }

    @media (max-width: 1201px) {

        .nav-tabs-container {
            justify-content: start;
        }

        .nav-tabs_btn {
            display: block;
        }
        &.nav-tabs-closed .nav-tabs-container {
            height: 0;
            overflow: hidden;
            transition: all 0.5s;
            border: none;
            padding: 0;
        }
        .nav-tabs-container {
            position: absolute;
            top: 52px;
            left: 0;
            width: 260px;
            flex-direction: column;
            z-index: 1000;
            transition: all 0.5s;
            background: #fff;
            height: calc(100vh - 52px);
            border-right: 1px solid $medium-gray;
            padding: 8px 4px 4px;
            margin: 0 !important;

            .nav-link {
                margin: 0 !important;
                min-width: 100%;
            }
        }
    }
}


