// debug
// * { outline: 1px solid blue; }

@import url(../fonts/OpenSans/fonts.css);
@import "variable";

@mixin font-styles-new-ui() {
  font-size: $font-size-base;
  line-height: 17px;
  color: $neutral;
}

div[onclick], div[role="button"] {
  cursor: pointer;
}

@mixin btn-styles-new-ui() {
  .btn {
    border-width: 1px;
    padding: 8px $padding-x;
    line-height: 16px;
    font-weight: 500;

    &.btn-fill {
      color: #ffffff;

      &:hover {
        border-color: $primary-dark-color-50;
        background: $primary-dark-color-50;
      }

      // TODO: Why so complicated?
      &:disabled,
      &[disabled],
      &.disabled,
      &:disabled:hover,
      &[disabled]:hover,
      &.disabled:hover {
        opacity: 1;
        color: $neutral-50;
        border-color: $neutral-12;
        background: $neutral-12;
      }
    }

    &.btn-outline-secondary {
      color: $primary-color;

      &:hover {
        background: $primary-color-8;
      }

      &:disabled,
      &[disabled],
      &.disabled,
      &:disabled:hover,
      &[disabled]:hover,
      &.disabled:hover {
        opacity: 1;
        color: $neutral-50;
        border-color: $neutral-20;
        background: transparent;

        svg {
          [class*='__stroke'] {
            stroke: $neutral-50;
          }
        }
      }
    }

    &.btn-simple {
      padding: 2px;

      &:hover {
        background: $primary-color-8;
      }

      &[disabled] {
        svg {
          [class*='__stroke'] {
            stroke: $neutral-50;
          }
        }
      }
    }

    &.btn-simple-highlight {
      padding: 2px;
      border: none;
      background: $primary-color-16;
      opacity: 1;

      svg {
        [class*='__stroke'] {
          stroke: $primary-color;
        }
      }

      &[disabled] {
        background: $neutral-4;

        svg {
          [class*='__stroke'] {
            stroke: $neutral-50;
          }
        }
      }
    }

    &.btn-outline-danger {
      color: $red;
      border-color: $red;

      &:hover {
        background: $red-4;
      }

      &:disabled,
      &[disabled],
      &.disabled,
      &:disabled:hover,
      &[disabled]:hover,
      &.disabled:hover {
        opacity: 1;
        color: $neutral-50;
        border-color: $neutral-20;
        background: transparent;
      }
    }
  }
}

  // TODO: move to new-ui?
// Is this all for TabView only?
.wrapper {
  overflow-y: hidden;

  .main-panel,
  & > .col {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    overflow: auto;
    padding: 0;

    & > .nav-tabs {
      min-height: unset;
    }

    & > .content {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;
      padding: 20px 24px 0;
      overflow: auto;
    }

    .new-ui {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;

      //  resetting old styles
      .card label,
      label {
        font-size: initial;
        line-height: initial;
        color: initial;
        text-transform: initial;
        margin: initial;
        padding: initial;
      }

      // end reset

    }

    .footer {
      background: transparent;
      height: auto;
      border: none;
    }
  }

  .station-container {
  }

  .new-ui {
    * {
      font-family: 'Open Sans', sans-serif;
    }

    h4,
    .h4 {
      font-size: 18px;
      line-height: 22px;
      margin: 0;
      padding: 0;
      font-weight: 700;
    }

    .text-black {
      color: $neutral;
    }

    .text-black-75 {
      color: $neutral-75;
    }

    .text-white {
      color: white;
    }

    .text-red {
      color: $red;
    }

    .text-orange {
      color: $warning;
    }

    .text-green {
      color: $success;
    }

    .fw-500 {
      font-weight: 500;
    }

    .gap-8 {
      gap: 8px;
    }

    .card {
      border-radius: 8px;
      border: 1px solid $neutral-12;
      box-shadow: 2px 6px 12px 0 rgba(38, 43, 47, 0.12);
      margin-bottom: $padding-y;

      &.header-box {
        .card-header.card-dark-header {
          background-color: $primary-color-6;
          border-bottom: 1px solid $neutral-12 !important;
          padding: 0 $padding-x;
          border-radius: 8px 8px 0 0;
          @include font-styles-new-ui;
        }
      }

      .card-body {
        padding: $padding-y $padding-x 16px;
      }
    }

    .nav {
      &-link {
        padding: 8px 0 6px;
        border-color: transparent;
        border-bottom: 2px solid transparent;
        color: $neutral-75;

        &:hover {
          border-color: transparent;
          border-bottom: 2px solid $neutral-75;
        }

        &.active {
          font-weight: 500;
          color: $neutral;
          border-color: transparent;
          border-bottom: 2px solid $primary-color;
        }
      }

      &-item {
        margin-right: 16px;
      }
    }

    .navigation-tabs {
      border-bottom: 1px solid $neutral-12;

      & a.disabled {
        color: $neutral-20;
      }

      .active {
        &.disabled {
          color: $disabled !important;
          border-bottom-color: $disabled;
        }
      }
    }

    .tab-pane {
      &.disabled * {
        color: $disabled !important;
      }
    }

    .icon-link-button {
      white-space: nowrap;
      color: $primary-color;
      display: inline-block;
      vertical-align: middle;


      svg {
        vertical-align: middle;
        display: inline-block;
        margin-right: $gap;
        margin-top: -2px;
      }

      &:hover {
        color: $primary-dark-color-50;

        // TODO: Is there a simpler way?
        svg {
          [class*='__stroke'] {
            stroke: $primary-dark-color-50;
          }
        }

        .text {
          &-red,
          &-warning {
            opacity: 0.75;
          }
        }

        .text-black {
          color: $primary-color;
        }
      }

      &_collapse {
        color: $neutral;

        & svg {
          color: $neutral;
          margin-right: 0;
        }
      }
    }

    .crops-container {
      height: 0;
      margin: -6px -24px 0 -16px;

      .crop-container {
        display: inline-block;
        position: relative;
        margin: 6px 0 0 6px;
        vertical-align: top;
        border: 2px solid transparent;
        border-radius: 4px;
        padding: 2px;
        cursor: pointer;
        background-clip: border-box;
        height: fit-content;

        .crop-icon {
          display: none;
          position: absolute;
          z-index: 1; // TODO: Why?

          &-zoom {
            left: 0;
            bottom: 0;
            width: 50%;
          }

          &-shelf {
            right: 0;
            bottom: 0;
            width: 50%;
          }

          &-zoom,
          &-shelf {
            height: 54px;
            justify-content: center;
            align-items: center;

            &::before {
              display: block;
              content: '';
              position: absolute;
              width: 38px;
              height: 38px;
              border-radius: 100%;
              background: white;
              left: 50%;
              top: 50%;
              margin-top: -19px;
              margin-left: -19px;
              z-index: 1;
              opacity: 0.5;
            }

            svg {
              position: relative;
              z-index: 2;
            }

            &:hover::before {
              opacity: 1;
            }
          }

          &-check {
            top: 2px;
            left: 2px;
          }

          &-mark {
            display: block;
            top: 2px;
            right: 2px;
          }
        }

        &:hover {
          background-image: linear-gradient(
                          to bottom,
                          #929597 0,
                          rgba($neutral, 0.02) 25%,
                          rgba($neutral, 0.02) calc(100% - 54px),
                          #929597 100%
          );

          .crop-icon {
            display: flex;
          }
        }

        &.selected {
          border-color: $primary-color;
          background-color: $primary-color-16;

          .crop-icon-check {
            display: block;
          }

          &:hover {
            background-image: none;
          }
        }

        &.moveFlag {
          &, &:hover {
            background-color: $warning-10;
          }
        }

        &.removeFlag {
          &, &:hover {
            background-color: $red-10;
          }
        }

        .crop {
          display: flex;
          justify-content: center;
          align-items: center;

          &.small {
            width: 115px;
            height: 145px;

            img {
              max-width: 107px; // TODO: calc from container values?
              max-height: 137px;
            }
          }

          &.medium {
            width: 149px;
            height: 188px;

            img {
              max-width: 141px;
              max-height: 180px;
            }
          }

          &.large {
            width: 175px;
            height: 220px;

            img {
              max-width: 167px;
              max-height: 212px;
            }
          }
        }
      }
    }

    @include btn-styles-new-ui();

    .labeler-switch {
      position: absolute;
      top: 8px;
      right: 0;
      z-index: 1101;
      * {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .crop-view {
      // TODO: Add hover for buttons
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100; // TODO: Is there a system to fit to the rest of the app? (Need 100 to overload bootstraps tabs)
      background-color: $neutral;
      padding: 80px 60px;

      &-button {
        position: absolute;
        cursor: pointer;

        &:hover {
          svg {
            [class*='__stroke'] {
              stroke: #fff;
            }
          }
        }
      }

      .button-prev {
        width: 48px;
        top: 80px;
        bottom: 80px;
        left: 0;

        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin-left: 24px;
        }
      }

      .button-next {
        width: 48px;
        top: 80px;
        bottom: 80px;
        right: 0;

        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .button-back {
        height: 48px;
        width: 48px;
        top: 24px;
        left: 24px;

        &:hover {
          svg {
            [class*='__fill'] {
              fill: $neutral-75;
            }
          }
        }

        svg {
          // TODO: make this a mixin or something like that?
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .button-select {
        height: 48px;
        width: 48px;
        top: 24px;
        left: 88px;

        svg {
          width: 48px;
          height: 48px;
        }
      }

      .button-shelf-view {
        height: 48px;
        width: 48px;
        top: 24px;
        right: 24px;
        border: 1px solid $neutral-75;
        border-radius: 100%;

        &:hover {
          background: $neutral-75;
        }

        svg {
          // TODO: make this a mixin or something like that?
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%);
          width: 24px;
          height: 24px;

          [class*='__stroke'] {
            stroke: $white-color;
          }
        }
      }
    }

    a.disabled {
      color: $neutral-50;
      pointer-events: none;

      span {
        color: $neutral-50;
      }

      svg path {
        stroke: $neutral-50;
      }
    }

    .alert {
      border-width: 1px;
      border-style: solid;
      padding: 16px 12px;
      border-radius: 8px;

      & * {
        @include font-styles-new-ui;
      }

      &-danger {
        border-color: $red;
        background: $red-10;
        margin-bottom: $padding-y;

        .bull-red {
          position: absolute;
          margin: 5px 0 0 -24px;
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background: $red;
          font-size: 0;
        }
      }
    }

    .empty-cluster {
      padding: 16px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: center;

      .main-text {
        display: block;
        font-size: 16px;
        color: $neutral;
        margin-top: 24px;
        font-weight: 500;
      }

      .additional-text {
        display: block;
        font-size: $font-size-small;
        color: $neutral-65;
        margin-top: 8px;
      }
    }

    .backdrop {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 8px;
    }

    .reject-list {
      overflow: hidden;
      max-height: 0;
      transition: 0.3s;

      &__opened {
        max-height: 124px;
      }
    }

    .custom-svg-spinner svg {
      animation: spin 3s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    // Fast Lane card
    .label-card {
      border: 1px solid transparent;
      min-height: 88px;
      font-size: $font-size-small;

      * {
        font-size: $font-size-small;
      }

      &_active {
        border-radius: 8px;
        border-color: $primary-color;
        background: $primary-color-8;
      }

      &_disabled {
        border-radius: 8px;
        border-color: $neutral-12;
        background: $neutral-4;
        cursor: auto;
      }

      &__text {
        color: $neutral-75;
      }

      &__wrapper + .label-card__wrapper:not(:has(.label-card_active)) {
        border-top: 1px solid $neutral-20;
      }

      &__wrapper:has(.label-card_active) + .label-card__wrapper {
        border-color: transparent;
      }

      &__image {
        position: relative;
        max-height: 88px;
        max-width: 88px;
        border-radius: 4px;
        background-image: linear-gradient(
                        to bottom,
                        $neutral-20 0,
                        rgba($neutral, 0.02) 25%,
                        rgba($neutral, 0.02) calc(100% - 54px),
                        $neutral-20 100%
        );
      }

      &__icon {
        position: absolute;
        z-index: 1; // overflow image
        right: 0;
        top: 0;
        width: 24px;

        &::before {
          display: block;
          content: '';
          position: absolute;
          width: 24px;
          height: 24px;
          border-radius: 100%;
          background: white;
          z-index: 1;
          opacity: 0.5;
        }

        svg {
          position: relative;
          z-index: 2;
        }

        &:hover::before {
          opacity: 1;
        }
      }

    }

    .icon-rotate {
      transition: transform 0.3s ease;
    }

    .rotated_90 {
      transform: rotate(90deg);
    }

    .container_scrolled {
      height: 0;
      min-height: 88px;
    }

    .input-group {
      &_background {
        border-radius: 4px;
        background: $neutral-4;

        .form-control {
          background: $neutral-4;
          border-color: $neutral-4;
        }
      }
    }

    .text-12 {
      font-size: $font-size-small;
    }
  }

  .bulk-menu__wrapper {
    // TODO: move to component
    .main-panel + & {
      margin-left: 260px;
    }

    @media (max-width: 991px) {
      .main-panel + & {
        margin-left: 0;
      }
    }

    .menu-action {
      // TODO: move to component
      color: white;
      display: flex;
      align-items: center;
      border-radius: 4px;
      height: 28px;
      padding: 0 8px;
      white-space: nowrap;
      cursor: pointer;
      gap: $gap;

      // TODO: Icon instead of svg?
      svg {
        margin-right: 8px;
      }

      &:hover {
        background: $neutral-75;
        color: white;
      }

      &:focus {
        background: $neutral-75;
      }
    }
  }
}
  // TODO: maybe add class new-ui to popover
  .popover {
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: $neutral;
    border-radius: 8px;
    border: 0.5px solid $neutral-12;
    background: $white-color;
    box-shadow: 0 4px 8px 0 rgba(38, 43, 47, 0.12),
    0 2px 4px 0 rgba(38, 43, 47, 0.12), 0 16px 24px 0 rgba(38, 43, 47, 0.16);

    &.bs-popover-right > .arrow::before {
      border-right-color: $neutral-12;
    }

    &.bs-popover-top > .arrow::before {
      border-top-color: $neutral-12;
    }

    &-header {
      background: $white-color;
      border: none;
      line-height: 24px;
      padding: $padding-y $padding-x 0;
      text-transform: uppercase;
      color: $neutral-50;
      font-weight: 500;
    }

    &-body {
      padding: $padding-y $padding-x;
    }

    .links-list {
      margin: -8px (-$padding-x);

      a {
        display: block;
        color: $neutral;
        padding: 8px $padding-x;

        &:hover {
          background: $neutral-8;
        }
      }

      .chosen {
        background: $neutral-8;
      }
    }

    input {
      border-radius: 4px;
      background: $neutral-4;
      padding: 8px;
      line-height: 18px;
      border: none;
      margin-right: $gap;
    }

    @include btn-styles-new-ui();
  }

  // TODO: Why not inside new-ui?
  .modal.modal-new-ui {
    * {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
    }

    .modal-content {
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 8px;
      box-shadow: 0 2px 4px 0 rgba(38, 43, 47, 0.25),
      0 2px 2px 0 rgba(38, 43, 47, 0.24);

      .modal-header {
        padding-top: 16px;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
      }

      .modal-body {
        padding-top: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        padding-bottom: 24px;
        color: $neutral-75;
      }

      .modal-footer {
        justify-content: flex-end;
        gap: 8px;

        .btn {
          min-width: 122px;
        }
      }

      .form-control {
        background: $neutral-4;
        border: none;
        padding: 8px;
      }
    }

    @include btn-styles-new-ui();

    .error-text {
      color: $red;
    }
  }


